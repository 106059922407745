import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import ErrorAlert from 'components/ErrorAlert';
import generalSignUpMutation from './generalSignUp.graphql';
import { useAuth } from 'context/AuthContext';
import { emailRegex } from 'utils/constants';

const Form = styled.form`
  .MuiFormControl-root,
  .MuiFormLabel-root {
    margin: 0;
  }
`;

const SubmitButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.625rem 1rem;
`;

HubspotForm.propTypes = {
  ebookSignUp: PropTypes.bool,
  leadSource: PropTypes.string,
  subscriptionId: PropTypes.string,
};

function HubspotForm({ ebookSignUp, leadSource, subscriptionId }) {
  const { user } = useAuth();
  const [email, setEmail] = useState(user?.email || '');
  const [honeypot, setHoneypot] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [emailError, setEmailError] = useState(null);

  const [generalSignUp, { loading, error }] = useMutation(generalSignUpMutation, {
    onCompleted: ({ generalSignUp: result }) => {
      if (result.updatedAt) {
        setSuccessful(true);
        setEmail('');
        setHoneypot(false);
      } else {
        setEmailError(result.errors[0].msg);
      }
    }
  });

  const validateEmail = (e) => {
    if (e.target.value.match(emailRegex)) {
      setEmailError(null);
    } else {
      setEmailError('Invalid email format');
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Clear alert tracking state before sending.
    setSuccessful(false);
    setEmailError(null);

    await generalSignUp({
      variables: {
        input: {
          email: email,
          leadSource: ebookSignUp ? 'eBook' : leadSource,
          subscriptionId: subscriptionId,
          honeypot: honeypot,
        }
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit} data-hs-cf-bound='true'>
      <Input
        id='email'
        onBlur={(e) => validateEmail(e)}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Email Address'
        required
        error={emailError}
        helperText={emailError}
        value={email}
      />
      <input
        type='checkbox'
        id='contact_me'
        name='contact_me'
        onChange={(e) => setHoneypot(e.target.checked)}
        style={{ display: 'none' }}
        tabIndex='-1'
        autoComplete='off'
      />
      <SubmitButton
        color='primary'
        type='submit'
        variant='contained'
        disabled={loading || emailError}
      >
        {loading ? 'Signing Up...' : 'Sign Up'}
      </SubmitButton>

      {successful && (
        <Box mt={2}>
          <Alert severity='success'>Success! Thanks for signing up!</Alert>
        </Box>
      )}

      {/* Adding alert with generic error if its an apollo error from the mutation */}
      {error && (
        <Box mt={2}>
          <ErrorAlert severity='error'>
            Uh oh, something went wrong with submitting your sign up request.
          </ErrorAlert>
        </Box>
      )}
    </Form>
  );
}

export default HubspotForm;
