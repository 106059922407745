import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RichTextField from 'components/RichTextField';
import Button from 'components/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Accordion from 'components/Accordion';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import Typography from '@material-ui/core/Typography';

const OuterContainer = styled(Grid)`
  padding: 3.5rem 2.5rem 3.5rem 3.875rem;
  width: 100%;

  ${props => props.theme.breakpoints.down('md')} {
    & > div:first-of-type {
      margin-bottom: 2.5rem;
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 2.5rem 1.5rem 2.5rem 1.75rem;

    & > div:first-of-type {
      margin-bottom: 2.5rem;
    }
  }
`;

const GridRow = styled(Grid)`
  .MuiGrid-item {
    display: flex;
  }

  margin-left: 0.5rem;

  ${props => props.theme.breakpoints.up('md')} {
    &:first-of-type {
      margin-left: -1.5rem;
    }
  }
`;

const IconContainer = styled.div`
  .MuiSvgIcon-root {
    fill: ${prop('theme.colors.ttRed')};
    height: 2.25rem;
    width: 2.25rem;
  }
`;

const Header = styled(Typography)`
  ${switchProp('weight', {
    medium: css`
      font-weight: 500;
    `,
    bold: css`
      font-size: 1.125rem;
      font-weight: 700;
    `
  })}
`;

const CopyContainer = styled.div`
  padding-left: 1rem;
`;

const GridDigital = styled(Grid)`
  ${props => props.theme.breakpoints.up('lg')} {
    padding-right: 2.5rem;
  }
`;

const ManageButton = styled(Button)`
  font-weight: 700;
  font-size: 0.875rem;
`;


LuckboxDetails.propTypes = {
  luckboxData: PropTypes.object
};

function LuckboxDetails({ luckboxData }) {
  const { hasDigitalSubscription } = luckboxData?.luckboxSubscriptions;

  return (
    <Accordion
      icon='chevronDown'
      id='panel4'
      title='Luckbox'
      variant='h4'
    >
      <OuterContainer container>
        {hasDigitalSubscription &&
          <>
            <GridDigital item md={12} lg={6}>
              <GridRow container>
                <Grid item xs={12}>
                  <IconContainer>
                    <CheckBoxIcon />
                  </IconContainer>
                  <CopyContainer>
                    <Header weight='bold'>
                      Luckbox Digital
                    </Header>
                    {luckboxData.luckboxOptions.digitalText &&
                      <RichTextField richText={luckboxData?.luckboxOptions.digitalText} />
                    }
                  </CopyContainer>
                </Grid>
              </GridRow>
            </GridDigital>
            <Grid item md={12} lg={6}>
              <ManageButton
                href='https://luckboxmagazine.com/profile/'
                target='_blank'
                rel='noopener'
                style={{ float: 'right' }}
              >
                Manage Luckbox Preferences <ChevronRightIcon fontSize='small' />
              </ManageButton>
            </Grid>
          </>
        }
      </OuterContainer>
    </Accordion>
  );
}

export default LuckboxDetails;
