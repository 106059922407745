import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextArea from 'components/TextArea';
import Input from 'components/Input';
import Button from 'components/Button';
import podcastIconPng from 'assets/images/icon-podcast.png';
import podcastIconWebp from 'assets/images/icon-podcast.webp';
import submitPodcastIdeaMutation from './submitPodcastIdea.graphql';
import { useMutation } from '@apollo/client';
import { useAuth } from 'context/AuthContext';
import ErrorAlert from 'components/ErrorAlert';
import { findFieldError } from 'utils/functions';
import { validateEmail } from 'utils/commongRegex';

const FormTitle = styled(Typography)`
  align-items: center;
  display: flex;
  margin-bottom: 2.5rem;
`;

const ImageContainer = styled.div`
  margin-right: 1rem;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: 2rem;
  }

  img {
    ${props => props.theme.breakpoints.down('xs')} {
      max-width: 2.5rem;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: 1.625rem;
  text-align: center;

  button {
    min-width: 12.5rem;
  }
`;

PodcastForm.propTypes = {
  onClose: PropTypes.func,
};

function PodcastForm({ onClose }) {
  const { user } = useAuth();
  const [fullName, setFullName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [idea, setIdea] = useState('');
  const [successful, setSuccessful] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [ideaError, setIdeaError] = useState(null);
  const [honeypot, setHoneypot] = useState(false);

  const [submitPodcastIdea, { loading, error }] = useMutation(submitPodcastIdeaMutation, {
    onCompleted: ({ submitPodcastIdea: result }) => {
      if (result.success) {
        setSuccessful(true);
        setFullName('');
        setEmail('');
        setIdea('');
        setHoneypot(false);
      } else {
        setNameError(findFieldError(result.errors, 'name'));
        setEmailError(findFieldError(result.errors, 'email'));
        setIdeaError(findFieldError(result.errors, 'idea'));
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear success tracking state before sending.
    setSuccessful(false);
    setNameError(null);
    setEmailError(null);
    setIdeaError(null);

    if (!validateEmail(email)) {
      setEmailError({ value: email, msg: 'Invalid Email' })
      return;
    }

    await submitPodcastIdea({
      variables: {
        input: {
          name: fullName,
          email,
          idea,
          honeypot,
        },
      },
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormTitle component='div'>
        <ImageContainer>
          <picture>
            <source srcset={podcastIconWebp} type='image/webp' />
            <source srcset={podcastIconPng} type='image/png' />
            <img alt='podcast icon' src={podcastIconPng} />
          </picture>
        </ImageContainer>
        <Typography variant='h2'>
          Have an idea? Send us a message.
        </Typography>
      </FormTitle>

      {successful && (
        <Box mb={2}>
          <Alert severity='success'>Success! Thanks for your awesome idea!</Alert>
        </Box>
      )}

      <Input
        id='fullName'
        label='First & Last Name'
        onChange={(e) => setFullName(e.target.value)}
        value={fullName}
        width='full'
        required
        error={nameError && fullName === nameError.value}
        helperText={nameError && fullName === nameError.value && nameError.msg}
      />
      <Input
        id='emailAddress'
        label='Email Address'
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        width='full'
        required
        error={emailError && email === emailError.value}
        helperText={emailError && email === emailError.value && emailError.msg}
      />
      <input
        type='checkbox'
        id='contact_me'
        name='contact_me'
        onChange={(e) => setHoneypot(e.target.checked)}
        style={{ display: 'none' }}
        tabIndex='-1'
        autoComplete='off'
      />
      <TextArea
        id='yourIdea'
        label='Your Idea'
        multiline={true}
        onChange={(e) => setIdea(e.target.value)}
        rows={8}
        type='textarea'
        value={idea}
        width='full'
        required
        error={ideaError && idea === ideaError.value}
        helperText={ideaError && idea === ideaError.value && ideaError.msg}
      />

      {/* If we get an apollo error from the mutation, it's not an error with specific field so we show generic error. */}
      {error && (
        <ErrorAlert severity='error'>
          Uh oh, something went wrong with submitting your podcast idea.
        </ErrorAlert>
      )}

      <ButtonContainer>
        <Button color='primary' type='submit' variant='contained' disabled={loading}>Send</Button>
      </ButtonContainer>
    </form>
  );
}

export default React.memo(PodcastForm);
